$spacings: ();

@for $i from 0 to 100 {
  $spacings: map-merge(
    $spacings,
    (
      "#{$i}": rem($i + 0px),
    )
  );
}

$paddings: "pl", "pr", "pt", "pb";
$margins: "ml", "mr", "mt", "mb";

@mixin getStyle($var, $size) {
  @if $var == "pl" {
    padding-left: $size !important;
  }

  @if $var == "pr" {
    padding-right: $size !important;
  }

  @if $var == "pt" {
    padding-top: $size !important;
  }

  @if $var == "pb" {
    padding-bottom: $size !important;
  }

  @if $var == "mr" {
    margin-right: $size !important;
  }

  @if $var == "ml" {
    margin-left: $size !important;
  }

  @if $var == "mt" {
    margin-top: $size !important;
  }

  @if $var == "mb" {
    margin-bottom: $size !important;
  }
}

/* Generates all padding spacing classes */
@each $var in $paddings {
  @each $name, $size in $spacings {
    .#{$var}-#{$name} {
      @include getStyle($var, $size);
    }
  }
}

/* Generates all margin spacing classes */
@each $var in $margins {
  @each $name, $size in $spacings {
    .#{$var}-#{$name} {
      @include getStyle($var, $size);
    }
  }
}
