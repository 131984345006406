.btn-xl {
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-border-radius-lg);
  padding: rem(0px);
  padding-top: rem(18px);
  padding-bottom: rem(18px);
  border-radius: rem(6px);
  max-height: 64px;
}

.btn-lg {
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-border-radius-lg);
  //padding: rem(9px) rem(24px);
  padding: rem(0px);
  padding-top: rem(12px);
  padding-bottom: rem(12px);
  border-radius: rem(4px);
  max-height: 48px;
}

.btn-md {
  @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-border-radius);
  padding: rem(0px);
  padding-top: rem(11px);
  padding-bottom: rem(11px);
  border-radius: rem(4px);
  max-height: 40px;
  line-height: initial;
}

.btn-sm {
  @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-border-radius-sm);
  padding: rem(0px);
  padding-top: rem(7px);
  padding-bottom: rem(7px);
  border-radius: rem(4px);
  max-height: 30px;
  line-height: initial;
}

//Should add 4px of padding to svg depending what side of text it appears on

.btn-xl > svg:first-child {
  margin-left: rem(20px);
  margin-right: rem(4px);
  vertical-align: top;
}

.btn-xl > span:last-child {
  padding-right: rem(24px);
  vertical-align: top;
}

.btn-xl > span:first-child {
  padding-left: rem(24px);
  vertical-align: top;
}

.btn-xl > svg:last-child {
  margin-right: rem(20px);
  margin-left: rem(4px);
  vertical-align: top;
}

.btn-xl > svg:only-child {
  margin-left: rem(18px);
  margin-right: rem(18px);
  vertical-align: top;
}

// btn lg margin/padding between text & icons
.btn-lg > svg:first-child {
  margin-left: rem(20px);
  margin-right: rem(4px);
  vertical-align: top;
}

.btn-lg > span:last-child {
  padding-right: rem(24px);
  vertical-align: top;
}

.btn-lg > span:first-child {
  padding-left: rem(24px);
  vertical-align: top;
}

.btn-lg > svg:last-child {
  margin-right: rem(20px);
  margin-left: rem(4px);
  vertical-align: top;
}

.btn-lg > svg:only-child {
  margin-left: rem(12px);
  margin-right: rem(12px);
  vertical-align: top;
}

// btn md margin/padding between text & icons

.btn-md > svg:first-child {
  margin-left: rem(16px);
  margin-right: rem(4px);
  vertical-align: top;
}

.btn-md > span:last-child {
  padding-right: rem(20px);
  vertical-align: top;
}

.btn-md > span:first-child {
  padding-left: rem(20px);
  vertical-align: top;
}

.btn-md > svg:last-child {
  margin-right: rem(16px);
  margin-left: rem(4px);
  vertical-align: top;
}

.btn-md > svg:only-child {
  margin-left: rem(11px);
  margin-right: rem(11px);
  vertical-align: top;
}

// btn sm margin/padding between text & icons

.btn-sm > svg:first-child {
  margin-left: rem(8px);
  margin-right: rem(4px);
  vertical-align: top;
}

.btn-sm > span:last-child {
  padding-right: rem(12px);
  vertical-align: top;
}

.btn-sm > span:first-child {
  padding-left: rem(12px);
  vertical-align: top;
}

.btn-sm > svg:last-child {
  margin-right: rem(8px);
  margin-left: rem(4px);
  vertical-align: top;
}

.btn-sm > svg:only-child {
  margin-left: rem(7px);
  margin-right: rem(7px);
  vertical-align: top;
}
/**
Primary button states
*/
.btn-primary {
  color: #ffffff;
  background-color: #1e5eff;
  border: 0;
}

.btn-primary:hover {
  background-color: #618eff;
  border: 0;
}

.btn-primary:active {
  background-color: #1745bb;
  border: 0;
}

.btn-primary:disabled {
  background-color: #d7dbec;
  border: 0;
}

.btn-primary:focus {
  background-color: #1e5eff;
  border-color: #1e5eff;
  box-shadow: 0 0 0 0.25rem #d2dfff;
}

/**
Primary button states in it's secondary color.
TODO This name is bad.
*/
.btn-primary-secondary {
  color: #1e5eff;
  background-color: #e9efff;
  border: 0;
}

.btn-primary-secondary:hover {
  background-color: #e9efff;
  border: 0;
}

.btn-primary-secondary:active {
  background-color: #e9efff;
  border: 0;
}

.btn-primary-secondary:disabled {
  background-color: #d7dbec;
  border: 0;
}

.btn-primary-secondary:focus {
  background-color: #e9efff;
  border-color: #e9efff;
  box-shadow: 0 0 0 0.25rem #e9efff;
}

/**
Destructive Primary / Danger Button
*/
.btn-danger {
  color: #ffffff;
  background-color: #f0142f;
  border: 0;
}

.btn-danger:hover {
  background-color: #f34359;
  border: 0;
}

.btn-danger:active {
  background-color: #d32035;
  border: 0;
}

.btn-danger:disabled {
  background-color: #d7dbec;
  border: 0;
}

.btn-danger:focus {
  background-color: #f0142f;
  border-color: #fcd5d9;
  box-shadow: 0 0 0 0.25rem #fcd5d9;
}

/**
Destructive Secondary / Danger Button
*/
.btn-danger-secondary {
  color: #f0142f;
  background-color: #fee8ea;
  border: 0;
}

.btn-danger-secondary:hover {
  background-color: #fee8ea;
  border: 0;
}

.btn-danger-secondary:active {
  background-color: #fee8ea;
  border: 0;
}

.btn-danger-secondary:disabled {
  background-color: #d7dbec;
  border: 0;
}

.btn-danger-secondary:focus {
  background-color: #fee8ea;
  border-color: #fee8ea;
  box-shadow: 0 0 0 0.25rem #fee8ea;
}

/**
Tertiary button states
*/
.btn-tertiary {
  background-color: #ffffff;
  color: #6a7388;
  border: solid 1px #cdd0d7;
}

.btn-tertiary:hover {
  color: #6a7388;
  background-color: #f7f8f9;
  border: solid 1px #cdd0d7;
}

.btn-tertiary:active {
  color: #6a7388;
  background-color: #f2f3f5;
  border: solid 1px #cdd0d7;
}

.btn-tertiary:disabled {
  color: #ffffff !important;
  background-color: #d7dbec;
  border: 0;
}

.btn-tertiary:focus {
  color: #6a7388;
  background-color: #ffffff;
  border-color: #d2dfff;
  border: solid 1px #cdd0d7;
  box-shadow: 0 0 0 0.25rem #d2dfff;
}

/**
Success button primary state.
The green button
*/
.btn-success-primary {
  color: #ffffff;
  background-color: #15a251;
  border: 0;
}

.btn-success-primary:hover {
  background-color: #15a251;
  border: 0;
}

.btn-success-primary:active {
  background-color: #15a251;
  border: 0;
}

.btn-success-primary:disabled {
  background-color: #d7dbec;
  border: 0;
}

.btn-success-primary:focus {
  background-color: #15a251;
  border-color: #15a251;
  box-shadow: 0 0 0 0.25rem #15a251;
}

/**
Success button secondary state.
*/
.btn-success-secondary {
  color: #15a251;
  background-color: #d0ecdc;
  border: 0;
}

.btn-success-secondary:hover {
  background-color: #d0ecdc;
  border: 0;
}

.btn-success-secondary:active {
  background-color: #d0ecdc;
  border: 0;
}

.btn-success-secondary:disabled {
  background-color: #d7dbec;
  border: 0;
}

.btn-success-secondary:focus {
  background-color: #d0ecdc;
  border-color: #d0ecdc;
  box-shadow: 0 0 0 0.25rem #d0ecdc;
}

//Button SVG size
.btn-xl-svg {
  width: rem(28px);
  height: rem(28px);
}
.btn-lg-svg {
  width: rem(24px);
  height: rem(24px);
}
//Medium-Large
.btn-ml-svg {
  width: rem(20px);
  height: rem(20px);
}
.btn-md-svg {
  width: rem(18px);
  height: rem(18px);
}
.btn-sm-svg {
  width: rem(16px);
  height: rem(16px);
}
.btn-xs-svg {
  width: rem(12px);
  height: rem(12px);
}

//Button Radius
.button-radius {
  border-radius: 50% !important;
}

//Start of button link css
.link {
  color: #1e5eff;
  cursor: pointer !important;
}

.link:hover {
  color: #618eff;
}

.link:active {
  color: #1745bb;
}

.link[disabled] {
  color: #b4b9c3;
}

.link-xl > svg:first-child {
  vertical-align: top;
  margin-right: rem(8px);
}

.link-lg > svg:first-child {
  vertical-align: top;
  margin-right: rem(8px);
}

.link-md > svg:first-child {
  vertical-align: top;
  margin-right: rem(4px);
}

.link-sm > svg:first-child {
  vertical-align: top;
  margin-right: rem(4px);
}

//Start of svg last child for link
.link-xl > svg:last-child {
  vertical-align: top;
  margin-left: rem(8px);
}

.link-lg > svg:last-child {
  vertical-align: top;
  margin-left: rem(8px);
}

.link-md > svg:last-child {
  vertical-align: top;
  margin-left: rem(4px);
}

.link-sm > svg:last-child {
  vertical-align: top;
  margin-left: rem(4px);
}

.link-text {
  //font-family: $regularFont;
  font-weight: normal;
  @extend .default-font-styling;
  margin: rem(0) rem(4px) rem(0) rem(4px);
}

/**
Large Link Button
*/

.link-text-lg {
  font-size: rem(20px);
}

/**
Medium Link Button
*/
.link-text-md {
  font-size: rem(16px);
}

/**
Small Link Button
*/
.link-text-sm {
  font-size: rem(14px);
}

/**
Extra Small Link Button
*/
.link-text-xs {
  font-size: rem(12px);
}

.icon-primary {
  fill: $primary !important;
}

.icon-secondary {
  fill: $secondary !important;
}

.icon-outline {
  fill: $outline !important;
}

.icon-muted {
  fill: $muted !important;
}

.icon-error {
  fill: $error !important;
}

/* Button Bar */
.button-bar:last-of-type button {
  margin-left: rem(12px);
}
