.tooltip {
  position: fixed;
  z-index: 9999;
  pointer-events: none;
  transition: opacity 0.15s ease-in-out;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  background-color: #071639;
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  font-size: 12px;
  max-width: 250px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  text-align: left;
}

.small {
  font-size: 12px;
}

.medium {
  font-size: 14px;
}

.large {
  font-size: 16px;
}

.contain {
  max-width: none;
  white-space: nowrap;
}

.tooltipVisible {
  opacity: 1;
}

.tooltipHidden {
  opacity: 0;
}

.tooltipArrow {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
}

// Arrow Directions
.tooltipArrowTop {
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 6px;
  border-color: #071639 transparent transparent transparent;
}

.tooltipArrowBottom {
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 6px;
  border-color: transparent transparent #071639 transparent;
}

.tooltipArrowLeft {
  right: -12px;
  top: 50%;
  transform: translateY(-50%);
  border-width: 6px;
  border-color: transparent transparent transparent #071639;
}

.tooltipArrowRight {
  left: -12px;
  top: 50%;
  transform: translateY(-50%);
  border-width: 6px;
  border-color: transparent #071639 transparent transparent;
}
