//Start of dropdowns custom css
.dropdown-button {
  background-color: white;
  color: #071639;
  border: solid rem(1px) #d1d5db;
  padding: rem(12px);
  text-align: start;
  width: 100%;
}
.dropdown-button:hover {
  color: $muted;
}

.dropdown-button-lg {
  //margin: rem(6px) rem(0) rem(0);
  padding: rem(12px);
  height: rem(48px);
  //font-family: Inter-Medium;
  font-size: rem(16px);
}
.dropdown-button-md {
  // margin: rem(6px) rem(0) rem(0);
  padding: rem(8px) rem(12px);
  height: rem(40px);
  //font-family: Inter-Medium;
  font-size: rem(16px);
}
.dropdown-button-sm {
  // margin: rem(6px) rem(0) rem(0);
  padding: rem(8px) rem(12px) rem(7px);
  height: rem(36px);
  //font-family: Inter-Medium;
  font-size: rem(14px);
}

.dropdown-item-text-sm {
  font-size: rem(14px);
}
.dropdown-item-text-lg {
  font-size: rem(16px);
}
.dropdown-item-text-md {
  font-size: rem(16px);
}
.dropdown-menu {
  // width: 100%;
  margin-top: rem(9px) !important;
}

// Individual dropdown items
.dropdown-checkable.dropdownItem {
  &-selected {
    background-color: #E9EFFF;
  }
  
  &-default {
    background-color: inherit;
  }
}

// Hover states
.dropdown-checkable {
  .dropdown-item:hover:not(.active) {
    color: inherit !important;
    background-color: inherit !important;
  }
}

// Form check elements
.dropdown-checkable {
  .form-check {
    display: flex;
    align-items: center;
    margin: 0;
    padding-left: 0;
  }

  .form-check-input {
    width: 14px;
    height: 14px;
    margin: 0;
    margin-right: 8px;
  }
}

.dropdown-item {
  @extend .text-sm;
  @extend .light-text-primary;
  @extend .text-medium;
  padding-top: rem(8px);
  padding-bottom: rem(8px);
  cursor: pointer;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #1e5eff;
  text-decoration: none;
  background-color: #ecf2ff;
}

.dropdown-item:hover:not(.active) {
  color: #1e5eff !important;
  background-color: white !important;
}

.dropdown-button-text {
  padding-left: rem(4px);
  //margin-right: rem(12px);
  align-items: center;
  //font-family: Inter-Medium;
}
.dropdown-active-icon {
  visibility: visible;
  float: right;
}

.box {
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072),
    0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
}

//Dropdown chevron direction based off aria-expanded value
/* .dropdown-chevron-up {
  display: none;
}
.dropdown-chevron {
  font-size: 8px;
  margin-left: 20px;
  vertical-align: middle;
} */
/* .dropdown-button[aria-expanded="true"] .dropdown-chevron-up {
  display: inline;
}

.dropdown-button[aria-expanded="true"] .dropdown-chevron-down {
  display: none;
}

.dropdown-button[aria-expanded="false"] .dropdown-chevron-down {
  display: inline;
} */

.dropdown-button[aria-expanded="true"] {
  border-color: $primary !important;
  outline: 0;
  border-radius: rem(6px);
  box-shadow: 0 0 0 0.1rem $primary;
}

//Disabled button
.dropdown-button:disabled,
.dropdown-button.disabled {
  background-color: #f1f4fa;
  color: #6b7280;
}

//Disable regular input
.regular-input:disabled,
.regular-input.disabled {
  background-color: #f1f4fa;
  color: #6b7280;
}
